import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHelperService } from '../shared/http-helper.service';
import { LoginInfo, Roles } from './auth.interface';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    
    constructor(
        private router: Router,
        private http: HttpClient,
        private httpHelperService: HttpHelperService) {}

    login(loginInfo: LoginInfo): Observable<LoginInfo> {
        return this.http.post(this.httpHelperService.getAuthApi() + 'login', JSON.stringify(loginInfo), this.httpHelperService.getHttpLoginHeaders())
            .pipe(
                map((res: LoginInfo) => {
                    window.sessionStorage.setItem('loginInfo', JSON.stringify(res));
                    return res; 
                }),
                catchError(this.httpHelperService.handleError)
            );
    }

    logout(): void {
        window.sessionStorage.removeItem('loginInfo');
        this.router.navigate(['/login']);
    }
    
    changePassword(newPassword: string): Observable<number> {
        return this.http.post(this.httpHelperService.getAuthApi() + 'changepassword', JSON.stringify(newPassword), this.httpHelperService.getHttpPostAuthHeaders())
            .pipe(
                map((res: number) => {
                    return res;
                }),
                catchError(this.httpHelperService.handleError)
            );
    }

    getLoginInfo(): LoginInfo {
        let loginInfo: LoginInfo = null;
        if (window.sessionStorage.getItem('loginInfo')) {
            loginInfo = JSON.parse(window.sessionStorage.getItem('loginInfo'));
        }
        
        return loginInfo;
    }

    updateTrackedUsers(trackedUsers: string): void {
        let loginInfo: LoginInfo = null;
        if (window.sessionStorage.getItem('loginInfo')) {
            loginInfo = JSON.parse(window.sessionStorage.getItem('loginInfo'));
            loginInfo.usersTracking = trackedUsers;
            window.sessionStorage.removeItem('loginInfo');
            window.sessionStorage.setItem('loginInfo', JSON.stringify(loginInfo));
        }
    }

    isUserLoggedIn(): boolean {
        let loginInfo: LoginInfo = this.getLoginInfo();
        return loginInfo == null ? false : loginInfo.role == Roles.User;
    }

    isAdminLoggedIn(): boolean {
        let loginInfo: LoginInfo = this.getLoginInfo();
        return loginInfo == null ? false : loginInfo.role == Roles.Admin;
    }
}