import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class DateTimeService {

    convertToUTC(date: string | Date): Date {
        let dateConverted: Date = new Date(date);
        return new Date(Date.UTC(dateConverted.getFullYear(), dateConverted.getMonth(), dateConverted.getDate(), dateConverted.getHours()));
    }
}