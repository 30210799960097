import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { LoginInfo } from '../auth/auth.interface';

@Injectable({
    providedIn: 'root'
})

export class HttpHelperService {

    private _baseApiUrl: string = 'https://lemans.southeastasia.cloudapp.azure.com/api/';

    getAuthApi(): string {
        return this._baseApiUrl + 'auth/';
    }

    getUserApi(): string {
        return this._baseApiUrl + 'user/';
    }

    getAdminApi(): string {
        return this._baseApiUrl + 'admin/';
    }

    getHttpLoginHeaders(): { headers : HttpHeaders } {
        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            })
        };
    }

    getHttpGetAuthHeaders(): { headers : HttpHeaders } {
        let token: string = '';
        if (window.sessionStorage.getItem('loginInfo')) {
            let loginInfo: LoginInfo = JSON.parse(window.sessionStorage.getItem('loginInfo'));
            token = loginInfo.token;
        }
        
        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
    }

    getHttpGetReportAuthHeaders(): { headers : HttpHeaders } {
        let token: string = '';
        if (window.sessionStorage.getItem('loginInfo')) {
            let loginInfo: LoginInfo = JSON.parse(window.sessionStorage.getItem('loginInfo'));
            token = loginInfo.token;
        }
        
        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': 'Bearer ' + token
            })
        };
    }

    getHttpPostAuthHeaders(): { headers : HttpHeaders } {
        let token: string = '';
        if (window.sessionStorage.getItem('loginInfo')) {
            let loginInfo: LoginInfo = JSON.parse(window.sessionStorage.getItem('loginInfo'));
            token = loginInfo.token;
        }
        
        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        let errorMsg: String = 'Unexpected Errour Encountered. Try again in 20 minutes time.';

        if (error.error != null)
        {
            if (error.error.message != undefined && error.error.message != null && error.error.message != '')
                errorMsg  = error.error.message;
        }
            
        if (error.error instanceof ErrorEvent) {
          console.error('An error occurred:', errorMsg);
        }
        return throwError(errorMsg);
    };
}