import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
    templateUrl: './change-password.dialog.html',
    styleUrls: ['./change-password.dialog.scss']
})

export class ChangePasswordDialog implements OnInit {

    usernames: string[] = Array(0);
    changePasswordFormGroup: FormGroup;

    private requiredFields: string[] = ['newPassword', 'confirmPassword'];

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordDialog>,
        private fb: FormBuilder) {}

    ngOnInit() {
        this.changePasswordFormGroup = this.fb.group({
            newPassword: [''],
            confirmPassword: ['', [this.passwordMatch('newPassword')]]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
    
    getErrorMessage(field: string): string {
        if (this.changePasswordFormGroup.controls[field].hasError('required')) {
            return 'You must enter a value';
        }
        else if (this.changePasswordFormGroup.controls[field].hasError('notMatch')) {
            return 'Passwords do not match';
        }
        return null;
    }

    getNewPassword(): string {
        return this.changePasswordFormGroup.controls['newPassword'].value;
    }

    isFormGroupInvalid(): boolean {
        return this.requiredFields.some(rf => this.changePasswordFormGroup.controls[rf].invalid);
    }

    passwordMatch(matchTo: string): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            return !!control.parent && !!control.parent.value &&
                control.value === control.parent.controls[matchTo].value ? null : { notMatch: true };
        };
    }
}