import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})

export class SnackBarService {

    constructor(
        private snackBar: MatSnackBar) {}
        
    openSuccessSnackBar(message: string) {
        this.snackBar.open(message, '', { duration : 3000, verticalPosition: 'top', panelClass: ['panel-success'] })
    }

    openErrorSnackBar(message: string) {
        this.snackBar.open(message, '', { duration : 3000, verticalPosition: 'top', panelClass: ['panel-error'] })
    }
}