import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LeaveRequestService } from '../../leave-request.service';
import { DateTimeService } from '../../datetime.service';
import { SnackBarService } from '../../snackbar.service';
import { AuthService } from '../../../auth/auth.service';
import { LeaveRequest, UserLeaveRequestTransfer, LeaveDefinitions, LeaveDefinition, LeaveRequestStatus, LeaveRequestStatuses, Months } from '../../shared.interface';
import { UserLeaveRequestApprovalDialog } from './user-leave-request-approval/user-leave-request-approval.dialog';

@Component({
    selector: 'leave-request-panel',
    templateUrl: './leave-request-panel.component.html',
    styleUrls: ['./leave-request-panel.component.scss']
})

export class LeaveRequestPanelComponent implements OnInit, OnDestroy {

    outgoingRequests: LeaveRequest[] = [];
    incomingRequests: LeaveRequest[] = [];
    leaveDefinitions: LeaveDefinition[] = LeaveDefinitions;
    leaveRequestStatuses: LeaveRequestStatus[] = LeaveRequestStatuses;
    
    private subs: Subscription[] = [];
    private months: string[] = Months;
    
    constructor(
        private authService: AuthService,
        private dateTimeService: DateTimeService,
        private snackBarService: SnackBarService,
        private leaveRequestService: LeaveRequestService,
        private dialog: MatDialog,) {}

    ngOnInit() {
        this.subscribeLeaveRequestChange();
    }

    ngOnDestroy() {
        this.unsubscribeLeaveRequestChange();
    }

    hasLeaveRequests(): boolean {
        return this.incomingRequests.length > 0 || this.outgoingRequests.length > 0;
    }

    reloadLeaveRequests(): void {
        this.leaveRequestService.reload().subscribe(data => {
            let loggedInUsername = this.authService.getLoginInfo().username;
            this.outgoingRequests = data.filter(r => r.username == loggedInUsername);
            this.incomingRequests = data.filter(r => r.relatedUsername == loggedInUsername);
        });
    }

    getFormattedDateTime(dt: Date): string {
        let time: string;
        let dateTime: Date = this.dateTimeService.convertToUTC(dt);
        if (dateTime.getUTCHours() == 0) {
            time = 'AM';
        }
        else if (dateTime.getUTCHours() == 12) {
            time = 'PM';
        }
        return `${dateTime.getUTCDate()}/${dateTime.getUTCMonth() + 1}/${dateTime.getUTCFullYear()} (${time})`;
    }

    getLeaveTypeFullName(abbr: string): string {
        let leave: LeaveDefinition = this.leaveDefinitions.find(l => l.abbr == abbr);
        if (leave) {
            return leave.fullName;
        }
        return '';
    }

    getLeaveRequestStatus(abbr: string): string {
        let status: LeaveRequestStatus = this.leaveRequestStatuses.find(s => s.abbr == abbr);
        if (status) {
            return status.fullName;
        }
        return '';
    }

    getFormattedMonth(from: Date, to: Date): string {
        let fromUTC: Date = this.dateTimeService.convertToUTC(from);
        let toUTC: Date = this.dateTimeService.convertToUTC(to);
        if (fromUTC.getUTCFullYear() == toUTC.getUTCFullYear() && fromUTC.getUTCMonth() == toUTC.getUTCMonth()) {
            return `${this.months[fromUTC.getUTCMonth()]} ${fromUTC.getUTCFullYear()}`;
        }
        return '';
    }

    deleteLeaveRequest(leaveRequest: LeaveRequest, event: MouseEvent): void {
        this.leaveRequestService.deleteLeaveRequest(leaveRequest).subscribe(data => {
            if (data > 0) {
                this.leaveRequestService.change(leaveRequest);
                this.snackBarService.openSuccessSnackBar('Leave request deleted.');
            }
        },
        (err: string) => {
            this.snackBarService.openErrorSnackBar(err);
        });
        event.stopPropagation();
    }

    archiveLeaveRequest(leaveRequest: LeaveRequest, event: MouseEvent): void {
        this.leaveRequestService.archiveLeaveRequest(leaveRequest).subscribe(data => {
            if (data > 0) {
                this.leaveRequestService.change(leaveRequest);
                this.snackBarService.openSuccessSnackBar('Leave request archived.');
            }
        },
        (err: string) => {
            this.snackBarService.openErrorSnackBar(err);
        });
        event.stopPropagation();
    }

    rejectUserLeaveRequest(leaveRequest: LeaveRequest, event: MouseEvent): void {
        this.leaveRequestService.rejectUserLeaveRequest(leaveRequest).subscribe(data => {
            if (data > 0) {
                this.reloadLeaveRequests();
                this.snackBarService.openSuccessSnackBar(`Leave request rejected.`);
            }
        },
        (err: string) => {
            this.reloadLeaveRequests();
            this.snackBarService.openErrorSnackBar(err);
        });
        event.stopPropagation();
    }

    approveUserLeaveRequest(leaveRequest: LeaveRequest, event: MouseEvent): void {
        this.openUserLeaveRequestApproveDialog(leaveRequest);
        event.stopPropagation();
    }

    private openUserLeaveRequestApproveDialog(leaveRequest: LeaveRequest): void {
        const dialogRef = this.dialog.open(UserLeaveRequestApprovalDialog, { width: '25vw', data: leaveRequest });

        dialogRef.afterClosed().subscribe((approval: UserLeaveRequestTransfer[]) => {
            if (approval) {
                this.leaveRequestService.approveUserLeaveRequest(leaveRequest, approval).subscribe(data => {
                    if (data > 0) {
                        this.reloadLeaveRequests();
                        this.leaveRequestService.approved(leaveRequest);
                        this.snackBarService.openSuccessSnackBar('Leave request approved.');
                    }
                },
                (err: string) => {
                    this.reloadLeaveRequests();
                    this.snackBarService.openErrorSnackBar(err);
                });
            }
        });
    }

    private subscribeLeaveRequestChange(): void {
        let sub: Subscription = this.leaveRequestService.leaveRequestChange.subscribe(data => {
            this.reloadLeaveRequests();
        });
        this.subs.push(sub);
    }

    private unsubscribeLeaveRequestChange(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }
}
