import { Component, OnInit, Inject, ComponentRef, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material';
import { DateTimeService } from '../../../datetime.service';
import { LeaveRequestService } from '../../../leave-request.service';
import { LoadingScreenService } from '../../../loading-screen.service';
import { LoadingScreenCardComponent } from '../../../loading-screen-card/loading-screen-card.component';
import { LeaveRequest, UserLeaveRequestTransfer, LeavePlan } from '../../../shared.interface';
import { AuthService } from 'src/app/modules/auth/auth.service';

@Component({
    templateUrl: './user-leave-request-approval.dialog.html',
    styleUrls: ['./user-leave-request-approval.dialog.scss']
})

export class UserLeaveRequestApprovalDialog implements OnInit {

    daysRequested: number;
    availableDates: UserLeaveRequestTransfer[] = [];
    selectedDates: UserLeaveRequestTransfer[] = [];
    
    private isLoading: boolean = false;
    private loadingScreen: ComponentRef<LoadingScreenCardComponent>;
    private loggedInUsername: string;

    constructor(
        public dialogRef: MatDialogRef<UserLeaveRequestApprovalDialog>,
        @Inject(MAT_DIALOG_DATA) public data: LeaveRequest,
        private authService: AuthService,
        private leaveRequestService: LeaveRequestService,
        private loadingScreenService: LoadingScreenService,
        private elementRef: ElementRef,
        private dateTimeService: DateTimeService) {}

    ngOnInit() {
        this.loggedInUsername = this.authService.getLoginInfo().username;
        this.daysRequested = this.data.daysRequested;
        this.loadLeaveRequestTransferAvailableDates(this.data)
    }

    getFormattedDate(date: Date): string {
        let formattedDate: Date = new Date(date);
        return `${formattedDate.getDate()}/${formattedDate.getMonth() + 1}/${formattedDate.getFullYear()}`;
    }

    getDaysSelected(): number {
        return this.selectedDates.filter(d => d.halfday).length * 0.5 + this.selectedDates.filter(d => !d.halfday).length;
    }

    isSelected(date: Date, halfday: boolean): boolean {
        let dateSelected: Date = new Date(date);
        let index = this.selectedDates.findIndex(d => d.transferDate.getTime() == dateSelected.getTime() && d.halfday == halfday);
        return index > -1;
    }

    isFullDayAvailable(date: Date): boolean {
        let dateSelected: Date = new Date(date);
        let available: UserLeaveRequestTransfer = this.availableDates.find(d => d.transferDate.getTime() == dateSelected.getTime());
        if (available) {
            return !available.halfday;
        }
        return false;
    }

    setSelectedDates(event: MatCheckboxChange, halfday: boolean): void {
        let dateSelected: Date = new Date(event.source.value);
        if (event.checked) {
            this.selectedDates.push({
                transferDate: dateSelected,
                halfday: halfday
            })
            let index = this.selectedDates.findIndex(d => d.transferDate.getTime() == dateSelected.getTime() && d.halfday == !halfday);
            if (index > -1) {
                this.selectedDates.splice(index, 1);
            }
        }
        else {
            let index = this.selectedDates.findIndex(d => d.transferDate.getTime() == dateSelected.getTime() && d.halfday == halfday);
            this.selectedDates.splice(index, 1);
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    isFormGroupInvalid(): boolean {
        return this.daysRequested != this.getDaysSelected();
    }

    private loadLeaveRequestTransferAvailableDates(leaveRequest: LeaveRequest): void {
        let fromUTC: Date = this.dateTimeService.convertToUTC(leaveRequest.leaveFrom);
        this.openCardLoadingScreen();
        this.leaveRequestService.getLeavePlans(this.loggedInUsername, fromUTC.getUTCFullYear(), fromUTC.getUTCMonth() + 1).subscribe(data => {
            if (data.length > 0) {
                let leavePlan: LeavePlan = data[0];
                for (let i: number = 1; i <= 31; i++) {
                    let plan: string = leavePlan[`day${i}`];
                    if (plan.includes('0.5')) {
                        this.availableDates.push({
                            transferDate: new Date(Date.UTC(leavePlan.year, leavePlan.month - 1, i)),
                            halfday: true
                        })
                    }
                    else if (plan != '0' && plan != '1' && plan != 'P' && plan != 'W') {
                        this.availableDates.push({
                            transferDate: new Date(Date.UTC(leavePlan.year, leavePlan.month - 1, i)),
                            halfday: false
                        })
                    }
                }
            }
            this.closeCardLoadingScreen();
        })
    }

    private openCardLoadingScreen(): void {
        if (!this.isLoading) {
            let cardContent: HTMLElement = (<HTMLElement>this.elementRef.nativeElement).querySelector('.card-content');
            this.loadingScreen = this.loadingScreenService.openCard(cardContent);
            this.isLoading = true;
        }
    }

    private closeCardLoadingScreen(): void {
        if (this.loadingScreen) {
            this.loadingScreenService.closeCard(this.loadingScreen);
            this.isLoading = false;
        }
    }
}