import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LeaveRequest, UserLeaveRequestTransfer, LeavePlan, UserLeaveRequestApproval } from './shared.interface';
import { HttpHelperService } from './http-helper.service';

@Injectable({
    providedIn: 'root'
})

export class LeaveRequestService {

    leaveRequestChange: BehaviorSubject<LeaveRequest> = new BehaviorSubject<LeaveRequest>(null);
    leaveRequestApproved: BehaviorSubject<LeaveRequest> = new BehaviorSubject<LeaveRequest>(null);

    constructor(
        private http: HttpClient,
        private httpHelperService: HttpHelperService) {}

    change(leaveRequest: LeaveRequest): void {
        this.leaveRequestChange.next(leaveRequest);
    }

    approved(leaveRequest: LeaveRequest): void {
        this.leaveRequestApproved.next(leaveRequest);
    }

    reload(): Observable<LeaveRequest[]> {
        return this.http.get(this.httpHelperService.getUserApi() + `getleaverequests`, this.httpHelperService.getHttpGetAuthHeaders())
            .pipe(
                map((res: LeaveRequest[]) => {
                    return res;
                }),
                catchError(this.httpHelperService.handleError)
            );
    }

    getLeavePlans(username: string, year: number, month: number): Observable<LeavePlan[]> {
        return this.http.get(this.httpHelperService.getUserApi() + `getleaveplans/${username}/${year}/${month}`, this.httpHelperService.getHttpGetAuthHeaders())
            .pipe(
                map((res: LeavePlan[]) => {
                    return res;
                }),
                catchError(this.httpHelperService.handleError)
            );
    }

    deleteLeaveRequest(leaveRequest: LeaveRequest): Observable<number> {
        return this.http.post(this.httpHelperService.getUserApi() + `deleteleaverequest`, JSON.stringify(leaveRequest), this.httpHelperService.getHttpPostAuthHeaders())
            .pipe(
                map((res: number) => {
                    return res;
                }),
                catchError(this.httpHelperService.handleError)
            );
    }

    archiveLeaveRequest(leaveRequest: LeaveRequest): Observable<number> {
        return this.http.post(this.httpHelperService.getUserApi() + `archiveleaverequest`, JSON.stringify(leaveRequest), this.httpHelperService.getHttpPostAuthHeaders())
            .pipe(
                map((res: number) => {
                    return res;
                }),
                catchError(this.httpHelperService.handleError)
            );
    }

    rejectUserLeaveRequest(leaveRequest: LeaveRequest): Observable<number> {
        return this.http.post(this.httpHelperService.getUserApi() + `rejectuserleaverequest`, JSON.stringify(leaveRequest), this.httpHelperService.getHttpPostAuthHeaders())
            .pipe(
                map((res: number) => {
                    return res;
                }),
                catchError(this.httpHelperService.handleError)
            );
    }

    approveUserLeaveRequest(leaveRequest: LeaveRequest, daysSelected: UserLeaveRequestTransfer[]): Observable<number> {
        let approval: UserLeaveRequestApproval = {
            request: leaveRequest,
            transfer: daysSelected
        }
        return this.http.post(this.httpHelperService.getUserApi() + `approveuserleaverequest`, JSON.stringify(approval), this.httpHelperService.getHttpPostAuthHeaders())
            .pipe(
                map((res: number) => {
                    return res;
                }),
                catchError(this.httpHelperService.handleError)
            );
    }
}