import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserAuthGuard } from './modules/auth/user-auth-guard.service';
import { AdminAuthGuard } from './modules/auth/admin-auth-guard.service';
import { LoginComponent } from './modules/auth/login/login.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'home', canLoad: [UserAuthGuard], loadChildren: () => import('./modules/user/user.module').then(mod => mod.UserModule) },
    { path: 'admin', canLoad: [AdminAuthGuard], loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule) },
    { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule { }