import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule, 
    MatInputModule, MatDialogModule, MatSnackBarModule, MatToolbarModule,
    MatGridListModule, MatNativeDateModule, MatSelectModule, MatExpansionModule, 
    MatBadgeModule, MatDividerModule, MatTooltipModule, MatMenuModule, MatListModule,
    MatProgressSpinnerModule, MatCardModule, MatTableModule, MatPaginatorModule,
    MatSortModule, MatSlideToggleModule, MatTabsModule } from '@angular/material';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LeaveRequestPanelComponent } from './nav-menu/leave-request-panel/leave-request-panel.component';
import { ChangePasswordDialog } from './nav-menu/change-password/change-password.dialog';
import { UserLeaveRequestApprovalDialog } from './nav-menu/leave-request-panel/user-leave-request-approval/user-leave-request-approval.dialog';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { LoadingScreenCardComponent } from './loading-screen-card/loading-screen-card.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatInputModule,
        MatDialogModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatGridListModule,
        MatNativeDateModule,
        MatSelectModule,
        MatExpansionModule,
        MatBadgeModule,
        MatDividerModule,
        MatTooltipModule,
        MatMenuModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatSlideToggleModule,
        MatTabsModule
    ],
    declarations: [
        PageNotFoundComponent,
        NavMenuComponent,
        LeaveRequestPanelComponent,
        ChangePasswordDialog,
        UserLeaveRequestApprovalDialog,
        LoadingScreenComponent,
        LoadingScreenCardComponent
    ],
    exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        PageNotFoundComponent,
        NavMenuComponent,
        LoadingScreenComponent,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatInputModule,
        MatDialogModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatGridListModule,
        MatNativeDateModule,
        MatSelectModule,
        MatExpansionModule,
        MatBadgeModule,
        MatDividerModule,
        MatTooltipModule,
        MatMenuModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatSlideToggleModule,
        MatTabsModule
    ],
    entryComponents: [ LoadingScreenComponent, LoadingScreenCardComponent, ChangePasswordDialog, UserLeaveRequestApprovalDialog ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    ],
})

export class SharedModule { }