import { Injectable } from '@angular/core';
import { Router, CanLoad, UrlSegment } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { AuthService }      from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class AdminAuthGuard implements CanLoad {

    constructor(private authService: AuthService, private router: Router) {}

    canLoad(next: Route, segments: UrlSegment[]): boolean {
        return this.checkLogin();
    }

    checkLogin(): boolean {
        if (this.authService.isAdminLoggedIn()) {
            return true;
        }
        else {
            this.router.navigate(['./login']);
            return false;
        }
    }
}