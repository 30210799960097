import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth/auth.service';
import { SnackBarService } from '../snackbar.service';
import { LeaveRequest, LeaveDefinitions, LeaveDefinition, LeaveRequestStatus, LeaveRequestStatuses } from '../shared.interface';
import { ChangePasswordDialog } from './change-password/change-password.dialog';

@Component({
    selector: 'nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss']
})

export class NavMenuComponent {

    leaveRequests: LeaveRequest[] = [];
    leaveDefinitions: LeaveDefinition[] = LeaveDefinitions;
    leaveRequestStatuses: LeaveRequestStatus[] = LeaveRequestStatuses;
    
    constructor(
        private router: Router,
        private location: Location,
        private dialog: MatDialog,
        private snackBarService: SnackBarService,
        private authService: AuthService) {}
    
    logout(): void {
        this.authService.logout();
    }

    isAdmin(): boolean {
        return this.authService.isAdminLoggedIn();
    }

    isOnAdminModule(): boolean {
        return this.location.path().startsWith('/admin');
    }

    getNavigateModuleName(): string {
        let navigateName = 'View ';
        if (this.isOnAdminModule()) {
            return navigateName + 'User Page';
        }
        else {
            return navigateName + 'Admin Page';
        }
    }

    switchModule(): void {
        if (this.isOnAdminModule()) {
            this.router.navigate(['/home']);
        }
        else {
            this.router.navigate(['/admin']);
        }
    }

    goToHome(): void {
        if (this.isOnAdminModule()) {
            this.router.navigate(['/admin']);
        }
        else {
            this.router.navigate(['/home']);
        }
    }

    getUserInitials(): string {
        let username: string = this.authService.getLoginInfo().name;
        if (username) {
            let nameSplit = username.split(' ');
            if (nameSplit.length > 1) {
                return nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase();
            }
            else {
                return nameSplit[0].charAt(0).toUpperCase();
            }
        }
        return '';
    }

    changePassword(): void {
        this.openChangePasswordDialog();
    }

    private openChangePasswordDialog(): void {
        const dialogRef = this.dialog.open(ChangePasswordDialog, { width: '30vw' });

        dialogRef.afterClosed().subscribe((newPassword: string) => {
            if (newPassword) {
                this.authService.changePassword(newPassword).subscribe(data => {
                    if (data > 0) {
                        this.snackBarService.openSuccessSnackBar('Password changed.');
                    }
                },
                (err: string) => {
                    this.snackBarService.openErrorSnackBar(err);
                });
            }
        });
    }
}
