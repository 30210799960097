import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoginInfo } from '../auth.interface';
import { AuthService } from '../auth.service';
import { LoadingScreenService } from '../../shared/loading-screen.service';
import { SnackBarService } from '../../shared/snackbar.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

    loginFormGroup: FormGroup;
    
    constructor(
        private authService: AuthService,
        private loadingScreenService: LoadingScreenService,
        private snackBarService: SnackBarService,
        private router: Router,
        private fb: FormBuilder) {}

    ngOnInit() {
        this.loginFormGroup = this.fb.group({
            username: [''],
            password: ['']
        })
        this.navigate();
    }

    login(): void {
        this.loadingScreenService.open();
        let loginInfo: LoginInfo = <LoginInfo>this.loginFormGroup.value;
        this.authService.login(loginInfo).subscribe((res: LoginInfo) => {
            this.navigate();
        },
        (err: string) => {
            this.loadingScreenService.close();
            this.snackBarService.openErrorSnackBar(err);
        });
    }

    private navigate(): void {
        if (this.authService.isAdminLoggedIn()) {
            this.router.navigate(['/admin']);
        }
        if (this.authService.isUserLoggedIn()) {
            this.router.navigate(['/home']);
        }
    }
}