export interface LoginInfo {
    username: string;
    password: string;
    name: string;
    role: string;
    token: string;
    usersTracking: string;
}

export const Roles = {
    Admin: "Admin",
    User: "User"
}