export interface UserBasic {
    name: string;
    username: string;
    role: string;
    checked: boolean;
}

export interface UserBilling {
    username: string;
    name: string;
    email: string;
    isBillable: boolean;
    billStartDate: Date;
    billEndDate: Date;
    rollOffDate: Date;
    rate: string;
    workforce: string;
    branch: string;
    team: string;
    io: string;
    isEditing: boolean;
}

export interface LeavePlan {
    username: string;
    name: string;
    year: number;
    month: number;
    day1: string;
    day2: string;
    day3: string;
    day4: string;
    day5: string;
    day6: string;
    day7: string;
    day8: string;
    day9: string;
    day10: string;
    day11: string;
    day12: string;
    day13: string;
    day14: string;
    day15: string;
    day16: string;
    day17: string;
    day18: string;
    day19: string;
    day20: string;
    day21: string;
    day22: string;
    day23: string;
    day24: string;
    day25: string;
    day26: string;
    day27: string;
    day28: string;
    day29: string;
    day30: string;
    day31: string;
    workingManDays: number;
    medicalManDays: number;
    caregiverManDays: number;
    childcareManDays: number;
    annualLeaveManDays: number;
    trainingManDays: number;
    reservistManDays: number;
    othersManDays: number;
    offdayManDays: number;
    comments: string;
}

export interface LeaveDefinition {
    abbr: string;
    fullName: string;
}

export interface LeaveRequestStatus {
    abbr: string;
    fullName: string;
}

export interface Holiday {
    holidayDate: Date;
    description: string;
    isRepeatable: boolean;
    isEditing: boolean;
}

export interface LeaveRequest {
    id: number;
    username: string;
    name: string;
    leaveFrom: Date;
    leaveTo: Date;
    daysRequested: number;
    leaveType: string;
    relatedUsername: string;
    relatedName: string;
    status: string;
    comments: string;
    dateSubmitted: Date;
}

export interface UserLeaveRequestTransfer {
    transferDate: Date;
    halfday: boolean;
}

export interface UserLeaveRequestApproval {
    request: LeaveRequest;
    transfer: UserLeaveRequestTransfer[];
}

export interface FileContentResult {
    fileContents: string;
    contentType: string;
    fileDownloadName: string;
}

export const LeaveDefinitions: LeaveDefinition[] = 
    [{ abbr : 'M', fullName: "Medical" },
    { abbr : 'CG', fullName: "Caregiver" },
    { abbr : 'CC', fullName: "Childcare" },
    { abbr : "A", fullName: "Annual" }, 
    { abbr : 'T', fullName: "Training" },
    { abbr : 'R', fullName: "Reservist" },
    { abbr : 'OF', fullName: "Offday" },
    { abbr : 'O', fullName: "Others" },
    { abbr : '-', fullName: "Cancel leave" }];

export const DaysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const Months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const LeaveRequestStatuses: LeaveRequestStatus[] =
    [{ abbr: 'P', fullName: 'Pending' },
    { abbr: 'A', fullName: 'Approved' },
    { abbr: 'R', fullName: 'Rejected' }];