import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef } from '@angular/core';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { LoadingScreenCardComponent } from './loading-screen-card/loading-screen-card.component';

@Injectable({
    providedIn: 'root'
})

export class LoadingScreenService {

    private dialogComponentRef: ComponentRef<LoadingScreenComponent>;
    private loading: boolean = false;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector) {}

    open(): void {
        if (!this.loading) {
            this.loading = true;
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingScreenComponent);
            const componentRef = componentFactory.create(this.injector);
            this.appRef.attachView(componentRef.hostView);

            const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            document.body.appendChild(domElem);

            this.dialogComponentRef = componentRef;
        }
    }

    close(): void {
        if (this.loading) {
            this.loading = false;
            this.appRef.detachView(this.dialogComponentRef.hostView);
            this.dialogComponentRef.destroy();
        }
    }

    openCard(htmlElement: HTMLElement): ComponentRef<LoadingScreenCardComponent> {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingScreenCardComponent);
        const componentRef = componentFactory.create(this.injector);
        this.appRef.attachView(componentRef.hostView);

        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        htmlElement.appendChild(domElem);

        return componentRef;
    }

    closeCard(componentRef: ComponentRef<LoadingScreenCardComponent>) {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }
}